var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'casting-spell': _vm.castingSpell
    },
    attrs: {
      "id": "app"
    }
  }, [!_vm.isStaticPage ? _c('amazon-payments-modal') : _vm._e(), _c('payments-success-modal'), _vm.isUserLoaded ? _c('sub-cancel-modal-confirm') : _vm._e(), _vm.isUserLoaded ? _c('sub-canceled-modal') : _vm._e(), _vm.isUserLoaded ? _c('bug-report-modal') : _vm._e(), _vm.isUserLoaded ? _c('bug-report-success-modal') : _vm._e(), _c('external-link-modal'), _c('birthday-modal'), _vm.isUserLoaded ? [_c('chat-banner'), _c('damage-paused-banner'), _c('gems-promo-banner'), _c('gift-promo-banner'), _c('birthday-banner'), _c('notifications-display'), _c('app-menu'), _c('div', {
    staticClass: "container-fluid",
    class: {
      'no-margin': _vm.noMargin,
      'groups-background': _vm.$route.fullPath === '/group-plans'
    }
  }, [_c('app-header'), _c('buyModal', {
    attrs: {
      "item": _vm.selectedItemToBuy || {},
      "with-pin": true,
      "generic-purchase": _vm.genericPurchase(_vm.selectedItemToBuy)
    },
    on: {
      "buyPressed": function ($event) {
        return _vm.customPurchase($event);
      }
    }
  }), _c('selectMembersModal', {
    attrs: {
      "item": _vm.selectedSpellToBuy || {},
      "group": _vm.user.party
    },
    on: {
      "memberSelected": function ($event) {
        return _vm.memberSelected($event);
      }
    }
  }), _c('div', {
    class: {
      sticky: _vm.user.preferences.stickyHeader
    }
  }, [_c('router-view')], 1)], 1), !_vm.hideFooter ? _c('app-footer') : _vm._e(), _c('audio', {
    ref: "sound",
    attrs: {
      "id": "sound",
      "autoplay": "autoplay"
    }
  })] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }